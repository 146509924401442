export default [
  {
    text: 'tag.table.name',
    value: 'name',
  },
  {
    text: 'tag.table.identifier',
    value: 'identifier',
  },
  {
    text: 'tag.table.sort',
    value: 'sortOrder',
  },
  {
    text: 'tag.table.recommendationTag',
    value: 'isRecommendationTag',
    default: false,
    type: 'slot',
  },
];
